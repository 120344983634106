import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { PillLink } from "../../components/ui/pill"
import Tags from "../../components/tags"
import { keywords } from "../../util/helpers"
import { IndexHeader } from "../../components/header"
import { blogCategoryVariant } from "../../util/styles"
import Icon from "../../components/icon"

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Blog Posts"
        description="Thoughts, ideas and random code snippets on software engineering and whatever else that pops into my head."
        path="/blog"
        keywords={[
          "software engineering blog",
          "web developer blog",
          "frontend engineering",
          "backend engineering",
          "coding",
          ...keywords(data.allMarkdownRemark.edges, "tags"),
        ]}
      />
      <section className="max-w-3xl px-5 mx-auto">
        <IndexHeader>Blog</IndexHeader>
        <ul className="divide-gray-300 divide-y">
          {data.allMarkdownRemark.edges
            .filter(({ node }) => {
              const rawDate = node.frontmatter.rawDate
              const date = new Date(rawDate)
              return date < new Date()
            })
            .map(({ node }) => {
              const { readingTime } = node.fields
              const {
                title,
                date,
                path,
                tags,
                category,
                technologies,
              } = node.frontmatter
              return (
                <li key={node.id} className="pb-10">
                  <div className="mb-2">
                    <PillLink
                      to={`/blog/categories/${category}`}
                      variant={blogCategoryVariant(category)}
                    >
                      {category}
                    </PillLink>
                  </div>
                  <Link to={path} className="no-underline group">
                    <div className="mb-3">
                      <div className="mb-1">
                        <div className="flex items-center">
                          <h3 className="font-extrabold group-hover:text-highlight-yellow inline leading-tight text-3xl text-primary-500">
                            {title}
                          </h3>
                          <div className="ml-3">
                            <ul className="flex items-center space-x-2">
                              {technologies.map(({ name }) => (
                                <li>
                                  <Icon
                                    icon={name.toLowerCase()}
                                    variant="dev"
                                    className="h-6"
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h5 className="inline text-gray-600">{date}</h5>
                        <h5 className="inline text-gray-600">
                          - {readingTime.text}
                        </h5>
                      </div>
                    </div>
                    <p className="text-gray-700">{node.excerpt}</p>
                  </Link>
                  <div className="mt-3 text-xs flex items-center">
                    <span className="text-gray-500 mr-3">Tags:</span>
                    <Tags tags={tags} />
                  </div>
                </li>
              )
            })}
        </ul>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        fileAbsolutePath: { regex: "/blog/" }
        frontmatter: { draft: { eq: false } }
      }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            rawDate: date
            path
            category
            tags {
              tagId
              tag
            }
            technologies {
              name
            }
          }
          fields {
            slug
            readingTime {
              text
            }
          }
          excerpt
        }
      }
    }
  }
`
